import { initializeBurgerMenu } from './js/burgerLogic';

document.addEventListener('DOMContentLoaded', () => {
  initializeBurgerMenu();
});

function generateBreadcrumbs() {
  const breadcrumbsContainer = document.getElementById('breadcrumbs');
  const pathArray = window.location.pathname.split('/').filter(part => part); // Убираем пустые элементы

  let breadcrumbHTML = `<a href="/">Home</a>`; // Начало с ссылки на Home
  let currentPath = '';

  pathArray.forEach((segment, index) => {
    currentPath += `/${segment}`;
    const capitalizedSegment = segment
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
    
    // Если это последний элемент, то делаем его текстом, а не ссылкой
    if (index === pathArray.length - 1) {
      breadcrumbHTML += ` > ${capitalizedSegment}`;
    } else {
      breadcrumbHTML += ` > <a href="${currentPath}/">${capitalizedSegment}</a>`;
    }
  });

  breadcrumbsContainer.innerHTML = breadcrumbHTML;
}

// Вызов функции для генерации хлебных крошек при загрузке страницы
generateBreadcrumbs();